<template>
    <v-dialog :value="value" @input="(val) => { $emit('input', val); }" :width="width" :max-width="maxWidth"
        :style="'z-index:' + zIndex ? zIndex : 200 + ';'" :persistent="persistent" scrollable>
        <template v-slot:activator="{on, attrs}">
            <slot name="activator" v-bind:attrs="attrs" v-bind:on="on"></slot>
        </template>
        <eod-card :loading="loading">
            <slot name="header">
                <v-card-title v-if="title">
                    <v-avatar v-if="dialogIcon" :color="dialogIconColor" size="40" class="elevation-3 mr-4">
                        <v-icon dark v-html="dialogIcon"></v-icon>
                    </v-avatar>
                    <div style="line-height:1em;">
                        <div>{{ title }}</div>
                        <template v-if="subtitle"><small style="line-height: 1em;" class="font-weight-light subtitle-1">{{ subtitle }}</small></template>
                    </div>
                    <v-spacer></v-spacer>
                    <slot name="headerActions"><v-btn @click="cancelAction" small icon><v-icon small>mdi-close</v-icon></v-btn></slot>
                </v-card-title>
                <v-divider></v-divider>
            </slot>
            
            <component :is="inset?'v-card-text':'div'" :style="{'padding-top:20px;':inset}" style="max-height:calc(100vh - 160px);overflow-y: auto;">
                <div class="text-body-1" style="height: 100%;">
                    <slot>
                        {{ body }}
                    </slot>
                </div>
            </component>
            <slot name="footer"></slot>
            <v-card-actions v-if="!hideActions && !loading" class="grey lighten-3 py-3 px-3">
                <slot name="actions">
                    <v-btn v-if="!hideCancel" text rounded @click="cancelAction">
                        {{ cancelText }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn :id="$helper.slugify(okText)" v-if="!hideOk" depressed rounded :color="dialogIconColor" @click="OkAction">
                        {{ okText }}
                    </v-btn>
                </slot>
            </v-card-actions>
        </eod-card>
    </v-dialog>
</template>
<script>
import { VCardText } from 'vuetify/lib'

export default {
    name:'eod-dialog',
    components:{
        VCardText
    },
    props: {
        value: Boolean,
        width: {
            type: Number | String,
            default: 400
        },
        maxWidth: {
            type: Number | String,
            default: '100%'
        },
        title: String,
        subtitle: String,
        body: String,
        loading: Boolean,
        type: String,
        icon: String,
        iconColor: String,
        zIndex: Number,
        hideCancel: Boolean,
        hideOk: Boolean,
        hideActions: Boolean,
        persistent: Boolean,
        inset: {
            type: Boolean,
            default: true
        },
        okText: {
            type: String,
            default: 'OK'
        },
        cancelText: {
            type: String,
            default: 'Annuleren'
        }
    },
    computed: {
        dialogIcon() {
            if (this.icon) {
                return this.icon;
            } else if (this.type == 'error') {
                return 'mdi-alert-circle-outline';
            } else if (this.type == 'success') {
                return 'mdi-check';
            }

            return null;
        },
        dialogIconColor() {
            if (this.iconColor) {
                return this.iconColor
            } else if (this.type == 'error') {
                return 'error';
            } else if (this.type == 'success') {
                return 'success';
            }
        }
    },
    methods: {
        OkAction() {
            this.$emit('okAction');
        },
        cancelAction() {
            this.$emit('cancelAction');
            this.$emit('input', false)
        }
    }
}
</script>