<template>
    <eod-card :to="isEditing?null:(value.link||null)" :loading="loading" v-if="value" class="w-100 h-100 d-flex flex-column position-relative eod-graph__card" title-class="pb-0 pt-2" v-bind="$attrs" v-on="$listeners" :title="value.title||null" :icon="value.icon?'mdi-'+value.icon:null" :dark="value.textColor == 'dark'" :color="value.bgColor||null">
        <template v-slot:header-actions v-if="!hideToolbar">
            <template v-if="!isEditing">
            <v-btn icon small v-if="value.description" @click="isInfoDialogVisible = true"><v-icon small>mdi-information-outline</v-icon></v-btn>
            <v-menu offset-y>
                    <template v-slot:activator="{attrs, on}">
                        <v-btn small icon v-bind="attrs" v-on="on"><v-icon small>mdi-dots-vertical</v-icon></v-btn>
                    </template>
                    <eod-card>
                        <v-list dense>
                            <v-list-item @click="$emit('maximize', value)" v-if="!isMaximized">
                                    <v-list-item-action class="mr-4"><v-icon small>mdi-arrow-expand</v-icon></v-list-item-action>
                                    <v-list-item-title>Vergroten</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="$emit('reload', value)">
                                    <v-list-item-action class="mr-4"><v-icon small>mdi-refresh</v-icon></v-list-item-action>
                                    <v-list-item-title>Herladen</v-list-item-title>
                            </v-list-item>
                            <v-menu offset-x v-if="CELL_TYPES[value.typeId].config && CELL_TYPES[value.typeId].config.exportTypes">
                                <template v-slot:activator="{attrs, on}">
                                    <v-list-item v-on="on" v-bind="attrs">
                                        <v-list-item-action class="mr-4"><v-icon small>mdi-database-export</v-icon></v-list-item-action>
                                        <v-list-item-title>Exporteren</v-list-item-title>
                                        <v-list-item-action class="mr-l"><v-icon small>mdi-chevron-right</v-icon></v-list-item-action>
                                    </v-list-item>
                                </template>
                                <v-list dense>
                                    <template v-if="CELL_TYPES[value.typeId].config.exportTypes.map(item => item.key).includes('csv')">
                                        <v-list-item v-if="series.length == 1" @click="$emit('exportData', 'csv', 0)">
                                            <v-list-item-action class="mr-4"><v-icon small>mdi-table</v-icon></v-list-item-action>
                                            <v-list-item-title>CSV</v-list-item-title>
                                        </v-list-item>
                                        <v-menu v-if="series.length > 1" offset-x>
                                            <template v-slot:activator="{attrs, on}">
                                                <v-list-item v-on="on" v-bind="attrs">
                                                    <v-list-item-action class="mr-4"><v-icon small>mdi-table</v-icon></v-list-item-action>
                                                    <v-list-item-title>CSV</v-list-item-title>
                                                    <v-list-item-action class="mr-l"><v-icon small>mdi-chevron-right</v-icon></v-list-item-action>
                                                </v-list-item>
                                            </template>
                                            <v-list dense>
                                                <v-list-item v-for="(serie, serieIndex) in series" :key="serieIndex" @click="$emit('exportData', 'csv', serieIndex)">
                                                    <v-list-item-action class="mr-4"><v-icon small>mdi-table</v-icon></v-list-item-action>
                                                    <v-list-item-title>{{value.series[serieIndex].label}}</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </template>
                                    
                                    <v-list-item v-if="CELL_TYPES[value.typeId].config.exportTypes.map(item => item.key).includes('image')" @click="$emit('exportData', 'image')">
                                        <v-list-item-action class="mr-4"><v-icon small>mdi-image</v-icon></v-list-item-action>
                                        <v-list-item-title>Afbeelding</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-list>
                    </eod-card>
                </v-menu>
                <v-btn small @click="$emit('close', value)" v-if="isMaximized" icon><v-icon small>mdi-close</v-icon></v-btn>
                <eod-dialog v-if="isInfoDialogVisible" v-model="isInfoDialogVisible" title="Info" :width="400" icon="mdi-information-outline" iconColor="primary">
                    <div class="mt-4" v-html="value.description"></div>
                    <template v-slot:actions>
                        <v-spacer></v-spacer>
                        <eod-btn @click="isInfoDialogVisible = false">Sluiten</eod-btn>
                    </template>
                </eod-dialog>
            </template>
            <template v-else>
                <eod-btn @click="$emit('edit', value)" small icon><v-icon small>mdi-pencil-outline</v-icon></eod-btn>
                <eod-btn @click="$emit('delete', value)" color="error" small icon><v-icon small>mdi-trash-can-outline</v-icon></eod-btn>
            </template>
        </template>
        <slot name="default"></slot>
    </eod-card>
</template>
<script>
import { CELL_TYPES } from './../../models/dashboard';
export default {
    name: 'eod-graph',
    props:{
        value: Object,
        isEditing: Boolean,
        isMaximized: Boolean,
        hideToolbar: Boolean,
        loading: Boolean,
        series: Array
    },
    data: () => ({
        isInfoDialogVisible: false,
        CELL_TYPES: CELL_TYPES
    }),
}
</script>