<template>
    <div>
        <v-row class="mb-0 mt-0">
            <v-col cols="auto">
                <v-chip color="grey lighten-3" label>Wat</v-chip>
            </v-col>
            <v-col>
                <eod-autocomplete :value="value.source"  :items="sources"
                    item-value="table"
                    @input="val => updateProperty('source', val)"></eod-autocomplete>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="mb-0 mt-0">
            <v-col cols="auto">
                <v-chip color="grey lighten-3" label>Velden</v-chip>
            </v-col>
            <v-col>
                                                <v-menu offset-y>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" color="grey"
                                                            icon><v-icon>mdi-plus-circle-outline</v-icon></v-btn>
                                                    </template>
                                                    <v-list>
                                                        <v-list-item
                                                            v-for="field in getSourceConfig().fields.filter(item => !(value.fields && value.fields.map(item => item.key).includes(item.key) && !item.type.aggregations))"
                                                            :key="field.key" @click="addField(field)">
                                                            <v-list-item-title>{{ field.text }}</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </v-col>
        </v-row>
        <draggable v-model="value.fields" group="form" handle=".handle">
                                        <v-row  class="mb-0 mt-0" v-for="(field, fieldIndex) in getFields()" :key="fieldIndex">
                                            <v-col cols="auto">
                                                <v-icon class="handle cursor-move">mdi-drag</v-icon>
                                            </v-col>
            <v-col cols="auto">
                                                    <v-chip color="primary">{{ field.field.text }}</v-chip>
                                                </v-col>
            <v-col>
                                                    <eod-autocomplete
                                                        v-if="field.field.type.aggregations"
                                                        :value="value.fields[fieldIndex].aggregation"
                                                        label="Bewerking"
                                                        item-value="key"
                                                        :items="field.field.type.aggregations"
                                                        @input="val => updateField(fieldIndex, 'aggregation', val)"></eod-autocomplete>
                                                </v-col>
                                                <v-col cols="auto">
                                                    <v-btn @click="deleteField(fieldIndex)" icon color="error"><v-icon small>mdi-trash-can-outline</v-icon></v-btn>
                                                </v-col>
                                                
        </v-row>
                                    </draggable>
        
        <v-divider></v-divider>
        <eod-query-fixed-filters :value="value.filters" @input="val => updateProperty('filters', val)" :fields="getSourceConfig().fields"></eod-query-fixed-filters>
        <v-divider></v-divider>
        <v-row class="mb-0 mt-0">
                                            <v-col cols="auto">
                                                <v-chip color="grey lighten-3" label>Dynamische filters</v-chip>
                                            </v-col>
                                            <v-col>
                                                <v-menu offset-y>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" color="grey"
                                                            icon><v-icon>mdi-plus-circle-outline</v-icon></v-btn>
                                                    </template>
                                                    <v-list>
                                                        <v-list-item
                                                            v-for="field in getSourceConfig().fields"
                                                            :key="field.key" @click="addDynamicFilter(field)">
                                                            <v-list-item-title>{{ field.text }}</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mb-0 mt-0" v-for="(filter, index) in getDynamicFields()"
                                            :key="'dynamic_filter_' + index">

                                            <v-col class="d-flex align-items-center" cols="auto">
                                                <v-chip color="primary">{{
                                                    filter.field.text }}</v-chip>
                                            </v-col>
                                            <v-col class="d-flex align-items-center" cols="auto">
                                                <v-text-field outlined hide-details dense
                                                    v-model="value.dynamic_filters[index].value"
                                                    label="label"></v-text-field>
                                            </v-col>
                                            <v-col class="d-flex align-items-center" cols="auto">
                                                <v-btn @click="removeDynamicFilter(index)" icon
                                                    color="error"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-divider></v-divider>
                                            <v-row class="mb-0 mt-0">
                                                <v-col cols="auto">
                                                    <v-chip color="grey lighten-3" label>Groeperen</v-chip>
                                                </v-col>
                                                <v-col>
                                                    <v-menu offset-y>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn v-bind="attrs" v-on="on" color="grey"
                                                                icon><v-icon>mdi-plus-circle-outline</v-icon></v-btn>
                                                        </template>
                                                        <v-list>
                                                            <v-list-item
                                                                v-for="field in getSourceConfig().fields.filter(item => !(value.group && value.group.includes(item.key)))"
                                                                :key="field.key" @click="addGrouping(field)">
                                                                <v-list-item-title>{{ field.text }}</v-list-item-title>
                                                            </v-list-item>
                                                        </v-list>
                                                    </v-menu>
                                                </v-col>
                                                <v-col v-if="value.group" cols="12">
                                                    <v-chip color="primary" class="mr-2 mb-2" v-for="(field, groupIndex) in getGroups()" @click:close="deleteGroup(groupIndex)" close :key="groupIndex">{{ field.text }}</v-chip>
                                                </v-col>
                                            </v-row>
                                        <template v-if="value.group && value.group.includes('time')">
                                            <v-divider></v-divider>
                                            <v-row class="mb-0 mt-0">
                                                <v-col cols="auto">
                                                    <v-chip color="grey lighten-3" label>Tijdsselectie</v-chip>
                                                </v-col>
                                                <v-col>
                                                    <v-row>
                                                        <v-col>
                                                            <v-checkbox class="mt-0" @change="val => updateProperty('ignoreTimeFilter', val)" :input-value="value.ignoreTimeFilter" label="Negeer de tijdsselectie"></v-checkbox>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </template>
    </div>
</template>

<script>
import eodQueryFixedFilters from './eod-query-fixed-filters.vue';
import {QUERY_SOURCES, QUERY_FIELDS} from './../plugins/queryeditor/config.js';
import draggable from 'vuedraggable';
export default {
    name: 'eod-query-builder',
    components:{
        eodQueryFixedFilters,
        draggable
    },
    props: {
        value: Object
    },
    data: () => ({
        sources: Object.values(QUERY_SOURCES)
    }),
    methods: {
        updateProperty(propertyName, newVal){
            const val = this.value;
            val[propertyName] = newVal;

            this.$emit('input', val);
        },
        getSourceConfig(){
            return QUERY_SOURCES[this.value.source];
        },
        updateField(index, fieldName, value){
            const val = this.value;
            val.fields[index][fieldName] = value;
            this.$emit('input', val);
        },
        getFields(){
            const fields = [];
            if(this.value && this.value.fields){
                for (let i = 0; i < this.value.fields.length; i++) {
                    const field = this.value.fields[i];
                    fields.push({
                        field: QUERY_FIELDS[field.key],
                        aggregation: field.aggregation
                    });
                }
            }
            return fields;
        },
        getGroups(){
            const fields = [];
            if(this.value && this.value.group){
                for (let i = 0; i < this.value.group.length; i++) {
                    const fieldKey = this.value.group[i];
                    fields.push(QUERY_FIELDS[fieldKey]);
                }
            }

            return fields;
        },
        deleteGroup(groupIndex){
            const val = this.value;
            val.group.splice(groupIndex, 1);
            this.$emit('input', val);
            this.refreshView();
        },
        addField(field){
            const val = this.value;
            if (!val.fields) {
                val.fields = [];
            }

            val.fields.push({
                key: field.key,
                aggregation:null,
            });

            if(val.group && !val.group.includes(field.key)){
                val.group.push(field.key);
            }

            this.$emit('input', val);
            this.refreshView();
        },
        deleteField(fieldIndex){
            const val = this.value;
            val.fields.splice(fieldIndex, 1);
            this.$emit('input', val);
            this.refreshView();
        },
        addGrouping(field){
            const val = this.value;
            if (!val.group || !val.group[0]) {
                if(val.fields){
                    val.group = val.fields.filter(item => !item.aggregation).map(item => item.key);
                } else {
                    val.group = [];
                }
            }

            if(!val.group.includes(field.key)){
                val.group.push(field.key);
            }

            this.$emit('input', val);
            this.refreshView();
        },
        getDynamicFields(){
            const filters = [];
            if(this.value && this.value.dynamic_filters){
                for (let i = 0; i < this.value.dynamic_filters.length; i++) {
                    const filter = this.value.dynamic_filters[i];
                    filters.push({
                        field: QUERY_FIELDS[filter.field.key],
                        value: filter.value
                    });
                }
            }

            return filters;
        },
        addDynamicFilter(filter) {
            const val = this.value;
            if (!val.dynamic_filters) {
                val.dynamic_filters = [];
            }

            val.dynamic_filters.push({
                field: {
                    endpoint: filter.endpoint,
                    key: filter.key,
                },
                value: filter.text
            });

            this.$emit('input', val);
            this.refreshView();
        },
        removeDynamicFilter(index) {
            const val = this.value;
            val.dynamic_filters.splice(index, 1);
            this.$emit('input', val);
            this.refreshView();
        },
        refreshView(){
            this.$nextTick(() => {
                this.$forceUpdate();
            })
        }
    }
}
</script>