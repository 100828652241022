import { QUERY_FIELDS, validateQueryTemplate } from "./../../../plugins/queryeditor/config";
import _ from 'lodash';
import eodGraph from './../eod-graph.vue';
import {v4 as uuidv4} from 'uuid';

export default{
    components:{
        eodGraph
    },
    props:{
        value: Object,
        filters: Array,
        isEditing: Boolean,
        from: Object, // Moment object
        until: Object // Moment object
    },
    data: () => ({
        series: [],
        resolvedItems: {},
        updateKey: 98343,
        id: uuidv4(),
        seriesToFetch: [],
        loading: false
    }),
    methods:{
        getRowFieldData(row, axis){

            if(!axis){
                return null;
            }

            if(!axis || !axis.key){
                return null;
            }

            let key = axis.key;
            if(axis.field && axis.field.key){
                const fieldConfig = QUERY_FIELDS[axis.field.key];

                if(fieldConfig.type.formatter){
                    return fieldConfig.type.formatter(row[key]);
                } else if(['module', 'treeview'].includes(fieldConfig.type.key) && fieldConfig.endpoint && this.resolvedItems[fieldConfig.endpoint+'_'+row[key]]){
                    return this.resolvedItems[fieldConfig.endpoint+'_'+row[key]].name;
                }
            }

            return row[key];
        },
        exportData(type, serieIndex){
            const label = (this.value.series && this.value.series[serieIndex])?(this.value.series[serieIndex].label||'data'):'data';
            switch(type){
                case 'csv':
                    if(this.series && this.series[serieIndex]){
                        const csv = this.$helper.objectsArrayToCSV(this.series[serieIndex]);
                        this.$helper.downloadText(csv, label+'.csv', 'text/csv;charset=utf-8;');
                    }
                    break;
                case 'image':
                    if (this.$refs[this.id] && this.$refs[this.id].getDataURL) {
                        const imageDataURL = this.$refs[this.id].getDataURL({
                            type: 'png',
                            pixelRatio: 2,
                            backgroundColor: '#FFFFFF',
                            excludeComponents: ['toolbox']
                        });
                        this.$eod.downloadImage(imageDataURL).then(response => {
                            this.$helper.downloadBlob(response.data, label+'.png');
                        });
                    }
                    break;
            }
        },
        getFieldByKey(key){
            return QUERY_FIELDS[key];
        },
        getAxisPropsByFieldKey(key){
            const field = this.getFieldByKey(key);

            if(field.type.key == 'number'){
                return {
                    // name:field.text,
                    type: 'value',
                    scale:false
                };
            } else if (field.type.key == 'timestamp'){
                const settings = {
                    type: 'time',
                    id: 'timeline',
                }

                if(this.from){
                    settings.min = this.from.format('YYYY/MM/DD[T]HH:mm:ss');
                }
                if(this.until){
                    settings.max = this.until.format('YYYY/MM/DD[T]HH:mm:ss');
                }

                return settings;
            } else {
                return {
                    // name:field.text,
                    type: 'category',
                    id: 'timeline'
                };
            }
        },
        mergeTileOptions(){
            if(this.value.graph && this.value.graph.options){
                this.options = _.merge(this.options, this.value.graph.options);
            }
        },
        mergeSerieOptions(serie, graphSerie){
            let merged = serie;
            if(serie && graphSerie.options){
                merged = _.merge(serie, graphSerie);
            }
            return merged;
        },
        getSerieQueryTemplates(){
            return new Promise((resolve) => {
                if(this.value.series){
                    const queryTemplateIds = this.value.series.filter(item => (typeof item.query === 'string')).map(item => item.query);
                    if(queryTemplateIds && queryTemplateIds[0]){
                        this.$eod.get('templates', ['id','name','content'], {
                            whereIn:[
                                {
                                    column: 'id',
                                    array: queryTemplateIds
                                }
                            ]
                        }).then(response => {
                            if(response.data && response.data.data && response.data.data.templates){
                                resolve(response.data.data.templates.edges);
                            } else {
                                resolve(null);
                            }
                        });
                    } else {
                        resolve(null);
                    }
                } else {
                    resolve(null);
                }
            });
        },
        finished() {
            this.$emit('graphLoaded', this.id, this.$refs[this.id]||null);
        },
        zoom(params){
            this.$emit('zoom', params);
        },
        loadData(){
            return new Promise(async resolve => {
                this.loading = true;
                this.series = [];
                this.seriesToFetch = {};
                if(this.value && this.value.series){
                    const queryTemplates = await this.getSerieQueryTemplates();
                    for (let i = 0; i < this.value.series.length; i++) {
                        const serie = this.value.series[i];

                        const unique = uuidv4();
                        this.seriesToFetch[unique] = i;
                        
                        const queryOptions = {};
                        let template = null;

                        if(typeof serie.query === 'object'){
                            template = _.cloneDeep(serie.query);
                        } else {
                            template = _.cloneDeep(this.$helper.getArrayItemByProperty(queryTemplates, 'id', serie.query));
                        }

                        if(!template){
                            return false;
                        }

                        if(validateQueryTemplate(template)[0]){
                            return false;
                        }

                        if(this.from && this.until){
                            queryOptions.from = this.from;
                            queryOptions.until = this.until;
                        } else if(this.$root.$children[0].selectedTime){
                            queryOptions.from = this.$moment.tz(this.$root.$children[0].selectedTime.from, 'Europe/Brussels').utc();
                            queryOptions.until = this.$moment.tz(this.$root.$children[0].selectedTime.until, 'Europe/Brussels').utc();
                        }

                        if(serie.filters){
                            template.content.filters.push(...serie.filters);
                        }
                
                        this.$eod.queryMeasurements(unique, template, this.filters, queryOptions).then(async result => {
                            if(result.data){
                                await this.resolveItems(result.data, template);

                                if(this.seriesToFetch.hasOwnProperty(result.unique)){
                                    this.series[this.seriesToFetch[result.unique]] = result.data;
                                    delete this.seriesToFetch[result.unique];
                                }
                            } else {
                               this.series[i] = [];
                            }

                            if(Object.values(this.seriesToFetch).length == 0){
                                this.loading = false;
                                resolve();
                            }
                        }).catch((e) => {
                            console.error('Error getting data of serie', template.id, e);
                            this.series[i] = [];
                            if(this.series.length == this.value.series.length){
                                this.loading = false;
                                resolve();
                            }
                        });
                    }
                } else {
                    this.loading = false;
                    resolve()
                }
            });
        },
        async resolveItems(data, template){
            const fields = template.content.fields;
            const toResolve = {};
            for (let i = 0; i < fields.length; i++) {
                const field = fields[i];
                const fieldConfig = QUERY_FIELDS[field.key];
                const fieldName = field.aggregation?field.aggregation+'_'+field.key:field.key;
    
                if(fieldConfig.endpoint){
                    for (let j = 0; j < data.length; j++) {
                        const row = data[j];
                        if(row[fieldName] && !this.resolvedItems.hasOwnProperty(fieldConfig.endpoint+'_'+row[fieldName])){
                            if(!toResolve[fieldConfig.endpoint]){
                                toResolve[fieldConfig.endpoint] = {
                                    field: fieldConfig,
                                    ids: []
                                };
                            }
                            toResolve[fieldConfig.endpoint].ids.push(row[fieldName]);
                            this.resolvedItems[fieldConfig.endpoint+'_'+row[fieldName]] = null;
                        }
                    }
                }
            }    
            for (const endpoint in toResolve) {
                if (Object.prototype.hasOwnProperty.call(toResolve, endpoint)) {
                    const config = toResolve[endpoint];
                    await this.resolveEndpointItems(endpoint, config);
                }
            }
        },
        async resolveEndpointItems(endpoint, config){
            let fields = ['id','name'];
            if(endpoint == 'users'){
                fields = ['id','firstName','lastName','username'];
            }
            await this.$eod.get(endpoint, fields, {
                        whereIn:[
                            {
                                column: 'id',
                                array: config.ids
                            }
                        ]
                    }).then(response => {
                        if(response.data && response.data.data && response.data.data[endpoint]){
                            for (let i = 0; i < response.data.data[endpoint].edges.length; i++) {
                                let resolvedItem = response.data.data[endpoint].edges[i];
    
                                if(config.field && config.field.itemClass){
                                    resolvedItem = new config.field.itemClass(resolvedItem);
                                }
                                this.resolvedItems[endpoint+'_'+resolvedItem.id] = resolvedItem;
                            }
                        }
                        this.updateKey++;
                        this.$forceUpdate();
                    });
        },
    }
}