import User from './../../models/user.js';
import moment from 'moment';

const QUERY_AGGREGATIONS = {
    count: {
        key: 'count',
        text: 'Aantal'
    },
    mean: {
        key: 'mean',
        text: 'Gemiddelde'
    },
    sum: {
        key: 'sum',
        text: 'Som'
    },
    last: {
        key: 'last',
        text: 'Laatste'
    },
    first: {
        key: 'first',
        text: 'Eerste'
    },
    max: {
        key: 'max',
        text: 'Hoogste'
    },
    min: {
        key: 'min',
        text: 'Laagste'
    },
    increase: {
        key: 'increase',
        text: 'Increase'
    },
    cumulativeSum: {
        key: 'cumulativeSum',
        text: 'Cumulatieve som',
        requiredGrouping: ['time']
    },
    spread: {
        key: 'spread',
        text: 'Verschil max - min'
    },
}

export const QUERY_FIELD_TYPES = {
    number: {
        key: 'number',
        operators: ['=','<','>','<>'],
        aggregations: Object.values(QUERY_AGGREGATIONS),
        filter:{
            component: () => import('./../../components/eod-text-field.vue')
        },
    },
    module: {
        key: 'module',
        operators: ['='],
        filter:{
            component: () => import('./../../components/eod-object-autocomplete.vue')
        },
        view: {
            component: () => import('./../../components/graphs2/fields/module.vue')
        }
    },
    treeview: {
        key: 'treeview',
        operators: ['='],
        filter:{
            component: () => import('./../../components/eod-objects-treeview-input.vue')
        },
        view: {
            component: () => import('./../../components/graphs2/fields/module.vue')
        }
    },
    timestamp: {
        key: 'timestamp',
        operators: ['=','<','>','<>'],
        formatter(value){
            return moment(value).format('YYYY-MM-DD HH:mm');
        },
        filter:{
            component: () => import('./../../components/eod-date-time-picker.vue')
        },
        view: {
            component: () => import('./../../components/graphs2/fields/timestamp.vue')
        }
    },
    text: {
        key: 'text',
        operators: ['=', '<>'],
        filter:{
            component: () => import('./../../components/eod-text-field.vue')
        },
    }
}

export const QUERY_FIELDS = {
    value: {
        key: 'value',
        text: 'Waarde',
        type: QUERY_FIELD_TYPES.number,
    },
    time: {
        key: 'time',
        text: 'Tijd',
        type: QUERY_FIELD_TYPES.timestamp,
    },
    message: {
        key: 'message',
        text: 'Bericht',
        type: QUERY_FIELD_TYPES.text,
    },
    status: {
        key: 'status',
        text: 'Status',
        type: QUERY_FIELD_TYPES.text,
    },
    severity: {
        key: 'severity',
        text: 'Niveau',
        type: QUERY_FIELD_TYPES.text,
    },
    project_id: {
        key: 'project_id',
        text: 'Project',
        type: QUERY_FIELD_TYPES.module,
        endpoint: 'projects'
    },
    product_id: {
        endpoint: 'products',
        key: 'product_id',
        text: 'Asset',
        type: QUERY_FIELD_TYPES.treeview,
        filter: {
            componentAttrs: {
                classes: ['COMPANY', 'PROJECT', 'DEVICE','DEVICE_FOLDER'],
                selectable: ['DEVICE']
            }
        }
    },
    product_type_id: {
        endpoint: 'productTypes',
        key: 'product_type_id',
        text: 'Type asset',
        type: QUERY_FIELD_TYPES.module,
    },
    owner_id: {
        key: 'owner_id',
        text: 'Eigenaar',
        type: QUERY_FIELD_TYPES.module,
        endpoint: 'users',
        itemClass: User
    },
    job_type_id: {
        key: 'job_type_id',
        text: 'Jobtype',
        type: QUERY_FIELD_TYPES.module,
        endpoint: 'jobTypes'
    },
    measurement_type_id: {
        key: 'measurement_type_id',
        text: 'Meetwaarde',
        type: QUERY_FIELD_TYPES.module,
        endpoint: 'measurementTypes'
    },
}

export const QUERY_SOURCES = {
    measurements: {
        table: 'measurements',
        text: 'Meetwaardes',
        fields: [QUERY_FIELDS.value,QUERY_FIELDS.time, QUERY_FIELDS.project_id, QUERY_FIELDS.product_id, QUERY_FIELDS.product_type_id, QUERY_FIELDS.owner_id, QUERY_FIELDS.job_type_id, QUERY_FIELDS.measurement_type_id],
    },
    events: {
        table: 'events',
        text: 'Alarmen',
        fields: [QUERY_FIELDS.value, QUERY_FIELDS.status, QUERY_FIELDS.severity, QUERY_FIELDS.time, QUERY_FIELDS.message, QUERY_FIELDS.project_id, QUERY_FIELDS.product_id, QUERY_FIELDS.owner_id, QUERY_FIELDS.job_type_id, QUERY_FIELDS.measurement_type_id],
    },
    logs: {
        table: 'logs',
        text: 'Logs',
        fields: [QUERY_FIELDS.time, QUERY_FIELDS.message, QUERY_FIELDS.project_id, QUERY_FIELDS.product_id, QUERY_FIELDS.owner_id, QUERY_FIELDS.job_type_id, QUERY_FIELDS.measurement_type_id],
    },
}

export function validateQueryTemplate(template){
    const content = template.content;
    const errors = [];
            
    if(!content.fields || !content.fields[0]){
        errors.push('Gelieve minstens 1 veld te selecteren');
        return errors;
    }

    const fieldsWithAggregation = content.fields.filter(item => item.aggregation);
    for (let i = 0; i < fieldsWithAggregation.length; i++) {
        const field = fieldsWithAggregation[i];
        const aggConfig = QUERY_AGGREGATIONS[field.aggregation];
        if(aggConfig.requiredGrouping){
            for (let j = 0; j < aggConfig.requiredGrouping.length; j++) {
                const groupField = aggConfig.requiredGrouping[j];
                if(!content.group || !content.group.includes(groupField)){
                    errors.push('Veld ' + QUERY_FIELDS[groupField].text + ' moet worden toegevoegd aan de groepering');
                }
            }
        }
    }

    if(content.group && content.group[0]){
        const fieldsWithoutAggregation = content.fields.filter(item => !item.aggregation).map(item => item.key)
        for (let i = 0; i < fieldsWithoutAggregation.length; i++) {
            const field = fieldsWithoutAggregation[i];
            if(!content.group.includes(field)){
                errors.push('Veld ' + QUERY_FIELDS[field].text + ' moet worden toegevoegd aan de groepering');
            }
        }
    } else if(fieldsWithAggregation[0]){
        errors.push('U moet minstens op 1 veld groeperen als je een bewerking wilt uitvoeren op een veld');
    }

    return errors;
}