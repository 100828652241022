import Template from './template';

export const CELL_EXPORT_TYPES = {
    csv: {
        key: 'csv',
        title: 'CSV'
    },
    image: {
        key: 'image',
        title: 'Afbeelding'
    }
}

export const CELL_TYPES = {
    tile: {
        key:'tile',
        icon: 'score',
        title: 'Waarde',
        info: 'Geef een enkele meetwaarde weer voor een snelle statuscontrole',
        defaultTile: {
            w:3,
            h:3,
            graph:{
                fields: []
            }
        },
        config:{
            exportTypes: [CELL_EXPORT_TYPES.csv],
            maxSeries: 1
        },
        viewComponent: () => import('./../components/graphs2/eod-graph-tile.vue'),
        configurationComponent: () => import('./../components/graphs2/configuration/eod-graph-tile.vue')
    },
    sankey: {
        key:'sankey',
        icon: 'flowchart',
        title: 'Stroomdiagram',
        info: 'Geef verbindingen weer a.d.h.v. een stroomdiagram',
        defaultTile: {
            w:3,
            h:6,
            graph:{}
        },
        config:{
            exportTypes: [CELL_EXPORT_TYPES.csv, CELL_EXPORT_TYPES.image],
            maxSeries: 1
        },
        viewComponent: () => import('./../components/graphs2/eod-graph-sankey.vue'),
        configurationComponent: () => import('./../components/graphs2/configuration/eod-graph-sankey.vue')
    },
    table: {
        key:'table',
        icon: 'table_chart',
        title: 'Tabel',
        info: 'Toon gestructureerde gegevens in een tabel voor gedetailleerd inzicht',
        defaultTile: {
            w:6,
            h:10,
            graph: {
                fields:[]
            },
        },
        config:{
            exportTypes: [CELL_EXPORT_TYPES.csv],
            maxSeries: 1
        },
        viewComponent: () => import('./../components/graphs2/eod-graph-table.vue'),
        configurationComponent: () => import('./../components/graphs2/configuration/eod-graph-table.vue')
    },
    line: {
        key:'line',
        icon: 'stacked_line_chart',
        title:'Lijngrafiek',
        info: 'Gebruik een lijngrafiek om de trend van uw data weer te geven',
        defaultTile: {
            w:4,
            h:6,
            graph: {
                options:{
                    legend:{
                        show: true
                    }
                }
            },
        },
        config:{
            exportTypes: [CELL_EXPORT_TYPES.csv, CELL_EXPORT_TYPES.image]
        },
        viewComponent: () => import('./../components/graphs2/eod-graph-line.vue'),
        configurationComponent: () => import('./../components/graphs2/configuration/eod-graph-line.vue')
    },
    heatmap: {
        key:'heatmap',
        icon: 'gradient',
        title:'Heatmap',
        info: 'Gebruik een heatmap om patronen en intensiteit in uw data te benadrukken',
        defaultTile: {
            w:4,
            h:6,
            graph: {
                options:{
                    visualMap:{
                        min: 0,
                        max: 10
                    },
                    legend:{
                        show: true
                    }
                }
            },
        },
        config:{
            exportTypes: [CELL_EXPORT_TYPES.csv, CELL_EXPORT_TYPES.image]
        },
        viewComponent: () => import('./../components/graphs2/eod-graph-heatmap.vue'),
        configurationComponent: () => import('./../components/graphs2/configuration/eod-graph-heatmap.vue')
    },
    gauge: {
        key:'gauge',
        icon: 'multiline_chart',
        title:'Graadmeter',
        info: 'Visualiseer een meetwaarde ten opzichte van een doel of drempelwaarde',
        defaultTile: {
            w:4,
            h:8,
            graph: {
                options:{
                    legend:{
                        show: true
                    }
                }
            },
        },
        config:{
            exportTypes: [CELL_EXPORT_TYPES.csv, CELL_EXPORT_TYPES.image]
        },
        viewComponent: () => import('./../components/graphs2/eod-graph-gauge.vue'),
        configurationComponent: () => import('./../components/graphs2/configuration/eod-graph-gauge.vue')
    },
    liquid: {
        key:'liquid',
        icon: 'humidity_percentage',
        title:'Vulgraad (%)',
        info: 'Gebruik een niveaumeter om de vulling van een tank of buffer visueel weer te geven en het percentage van de maximale capaciteit te tonen',
        defaultTile: {
            w:4,
            h:8,
            graph: {
                options:{
                    legend:{
                        show: true
                    }
                }
            },
        },
        config:{
            exportTypes: [CELL_EXPORT_TYPES.csv, CELL_EXPORT_TYPES.image],
            maxSeries: 1
        },
        viewComponent: () => import('./../components/graphs2/eod-graph-liquid.vue'),
        configurationComponent: () => import('./../components/graphs2/configuration/eod-graph-liquid.vue')
    },
    pie: {
        key:'pie',
        icon: 'pie_chart',
        title:'Taartgrafiek',
        info: 'Gebruik een taartdiagram om de verdeling van uw data visueel weer te geven',
        defaultTile: {
            w:4,
            h:8,
            graph: {
                tooltip:{
                    valueFormat: null
                },
                options:{
                    legend:{
                        show: true
                    }
                }
            },
        },
        config:{
            exportTypes: [CELL_EXPORT_TYPES.csv, CELL_EXPORT_TYPES.image],
            maxSeries: 1
        },
        viewComponent: () => import('./../components/graphs2/eod-graph-pie.vue'),
        configurationComponent: () => import('./../components/graphs2/configuration/eod-graph-pie.vue')
    },
    text: {
        key:'text',
        icon: 'text_fields',
        title:'Tekst',
        info: 'Toon belangrijke informatie met optioneel een directe link naar aanvullende details',
        steps: [1,3],
        defaultTile: {
            w:4,
            h:8,
            graph: {
                content:null
            },
        },
        viewComponent: () => import('./../components/graphs2/eod-graph-text.vue'),
        configurationComponent: () => import('./../components/graphs2/configuration/eod-graph-text.vue')
    }
};

export const CELL_GROUPS = {
    graphs: {
        key:'graphs',
        title: 'Grafieken',
        cellTypes: [CELL_TYPES.tile, CELL_TYPES.table, CELL_TYPES.line,CELL_TYPES.pie, CELL_TYPES.gauge, CELL_TYPES.liquid, CELL_TYPES.heatmap, CELL_TYPES.sankey]
    },
    other: {
        key:'other',
        title: 'Andere',
        cellTypes: [CELL_TYPES.text]
    }
}

export default class Dashboard extends Template {

}