<template>
    <eod-graph v-bind="{...$props, ...$attrs}" v-on="$listeners" @reload="reload" :series="series" @exportData="exportData" :loading="loading">
        <div class="flex-grow-1 overflow-hidden">
        <v-data-table :items="data" :headers="headers" fixed-header>
            <template v-for="header in headers" v-slot:[header.slotName]="{ item, header }">
                <td>
                    <component :value="item[header.value]" :resolvedItems="resolvedItems" :config="header.config" :is="header.config.type.view && header.config.type.view.component?header.config.type.view.component:'span'">
                        {{ item[header.value] }}
                    </component>
                </td>
            </template>
        </v-data-table>
    </div>
    </eod-graph>
</template>
<script>
import graphMixin from './mixins/graph.js';

export default {
    name: 'eod-graph-table',
    mixins:[graphMixin],
    data: () => ({
        headers: [],
        data: []
    }),
    mounted(){
        this.reload();
    },
    methods: {
        reload(){
            this.loadData().then(() => {
                this.update();
            });
        },
        update(){
            this.initHeaders();
            this.initData();
        },
        initData(){
            this.data = [];
            if(this.series[0]){
                this.data = this.series[0];
            }

            this.$forceUpdate();
        },
        initHeaders(){
            this.headers = [];

            if(this.value && this.value.graph && this.value.graph.fields){
                for (let i = 0; i < this.value.graph.fields.length; i++) {
                    const field = this.value.graph.fields[i];

                    const fieldConfig = this.getFieldByKey(field.fieldKey);

                    if(fieldConfig){
                        this.headers.push({
                            value: field.key,
                            text: field.label?field.label:fieldConfig.text,
                            config: fieldConfig,
                            slotName: 'item.' + field.key,
                        });
                    }
                }
            }
            this.$forceUpdate();
        },
    }
}
</script>