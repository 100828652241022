<template>
    <div>
        <div class="d-flex">
            <v-autocomplete :id="$attrs.label?'autocomplete_'+$helper.slugify($attrs.label):null" dense outlined hide-details v-on="$listeners" v-bind="$attrs" :style="info?'border-top-right-radius: 0;border-bottom-right-radius: 0;':''">
                <template v-slot:item="scope">
                    <slot name="item" v-bind="{...scope}">
                    <v-list-item v-bind="scope.attrs" v-on="scope.on">
                        <v-list-item-content>
                            <v-list-item-title v-if="scope.item instanceof Object">{{ $attrs['item-text']?scope.item[$attrs['item-text']]:scope.item.text }}</v-list-item-title>
                            <v-list-item-title v-else>{{ scope.item }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    </slot>
                </template>
                <template v-slot:append-item>
                    <slot name="append-item"></slot>
                </template>
            </v-autocomplete>
            <div v-if="info" class="grey lighten-3 d-flex align-center" style="border-top-right-radius: 5px;border-bottom-right-radius: 5px;">
                <v-tooltip top open-on-click :open-on-focus="false">
                    <template v-slot:activator="{on, attrs}">
                        <v-btn v-bind="attrs" v-on="on" icon small><v-icon small>mdi-information-variant</v-icon></v-btn>
                    </template>
                    {{ info }}
                </v-tooltip>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        info: String,
    },
    name: 'eod-autocomplete',
}
</script>