<template>
    <div class="h-100 w-100">
        <v-chart :ref="id" :initOptions="graphOptions" :autoresize="true" :manualUpdate="true"/>
    </div>
</template>
<style lang="scss">
    .echarts {
        width: 100%;
        height: 100%;
    }
</style>
<script>
    import ECharts from './echarts/ECharts';
    import _ from 'lodash';

    export default {
        name: 'eod-graph-sankey',
        components:{
            'v-chart': ECharts,
        },
        props:{
            id: String,
            value:Object|Array,
            template:Object|Array,
            xAxis: Object|Array,
            yAxis: Object|Array,
            group: String,
            from: Object,
            until: Object,
            cell: Object,
            filters: {
                type: Array,
                default: () => {
                    return []
                }
            },
            variables:Object,
            options: {
                type: Object,
                default(){
                    return {}
                }
            },
            defaultSerie:Object
        },
        data() {
            return {
                graphOptions: null,
            }
        },
        methods: {
            async update(){
                if(this.cell.settings.json){

                    const json = this.cell.settings.json;
                    const tags = this.$helper.getUsedTokens(json);
                    
                    // Collect all product ID's needed
                    const productIds = [];
                    for (let j = 0; j < tags.length; j++) {
                        const tag = tags[j].replace('{{', '').replace('}}', '');
                        const parts = tag.split('.');
                        if(parts[0] && parts[0] == 'products' && parts[1] && this.$helper.isUUID(parts[1])){
                            productIds.push(parts[1]);
                        }
                    }
                    
                    if(productIds.length > 0){
                        // Resolve products
                        const products = await this.$eod.get('resolveProducts', ['id','name', 'measurementTypes{id, indicativeValue}'], {
                            whereIn:[{
                                column: 'id',
                                array: productIds
                            }]
                        }).then(response => {
                            if(response.data.data && response.data.data.resolveProducts){
                                const itemsById = {};
                                for (let i = 0; i < response.data.data.resolveProducts.edges.length; i++) {
                                    const product = response.data.data.resolveProducts.edges[i];
                                    itemsById[product.id] = product;
                                }
                                return itemsById;
                            }

                            return null;
                        });
                        
                        const template = this.template[0];

                        // Resolve values
                        if(this.value && this.value[0]){
                            const measurementTypes = Object.values(this.value[0].measurementTypes);
                            if(measurementTypes[0]){
                                const measurementType = measurementTypes[0];
                                let aggregation = 'last';
                                if(template.content.measurementTypes && template.content.measurementTypes[0]) {
                                    aggregation = template.content.measurementTypes[0].aggregation;
                                }                          
                                for (const productId in products) {
                                    const product = products[productId];
                                    const val = this.$helper.getArrayItemByProperty(this.value[0].data, 'product_id', productId);
                                    products[productId].value = 'null';
                                    if(val && val[aggregation+'_'+measurementType.id]){
                                        products[productId].value = parseFloat(val[aggregation+'_'+measurementType.id]);
                                    } else if(product.measurementTypes) {
                                        const productMeasurementType = this.$helper.getArrayItemByProperty(product.measurementTypes, 'id', measurementType.id);
                                        if(productMeasurementType){
                                            products[productId].value = productMeasurementType.indicativeValue;
                                        }
                                    }
                                }
                            }
                        }

                        const jsonReplaced = this.$helper.replaceTags(json, {
                            products: products
                        });

                        this.graphOptions = JSON.parse(jsonReplaced);
                    }else{
                        this.graphOptions = JSON.parse(json);
                    }
                }else{
                    this.graphOptions = null;
                }

                if(this.$refs[this.id]){
                    this.$refs[this.id].mergeOptions(this.graphOptions);
                    this.$nextTick(() => {
                        this.$forceUpdate();
                    });
                }
            }
        }
    }
</script>